const presets = [
  {
    "buildString": "51a13aa17i7a",
    "levelString": "11e8.4131373i3d3g3e7b7c7e506450-21f697273506e-216-2i250-2i8-28a6r7f7g7h50-28y6s50-614-28x50-28e-273-2ca6t506u-2cj-2ea56_2e157i74-2fa5a_2f2q5a-2fc_2a285a_2a1b51-2a27l7m7n7t",
    "level": 61,
    "HP": 255,
    "avatar": "VaultSuit",
    "name": "Multi-Purpose SG Explorer",
    "class": "Small Guns Survivor",
    "role": "PvE, First Character",
    "notes": "Uses Shotguns, Assault Rifles, SMGs and SG pistols including the Gauss Pistol. Walks the map with ease, recovers quickly from knockdowns, heals wounds, leads 3 NPC Companion followers, picks locks, ignores bear traps, repairs gear w/max success, and gets multiple crafting professions (SG 2, BG 2, Armorer 2, Doctor 2, Demolitions 1, Science 1) and more with more levels."
  },
  {
    "buildString": "51a17a627ie",
    "levelString": "5be8.4252-2dm77637b7c-2dg53_22146953-22g6e-221536b-22c53_27196r-27153_281l-614-28153_28176u53-28b6g-222-278-28156-27q5a-2727273767879_2e2w_2d1974",
    "level": 40,
    "HP": 255,
    "avatar": "ThermalLeatherArmor",
    "name": "Flamer for beginners",
    "class": "Incinerator",
    "role": "Leveling char",
    "author": "Powerpuff",
    "notes": "-  lvl 1 big gun profession from Marshal (Gun Runners)\n\n- lvl 2 + 3 invest points into Science and get Swift Learner perk\n\n- lvl 3 and ongoing invest into big guns.. don't forget to also get 125 FA + 125 DOC to be able to take medic perk.\n\n- books were not used while building this, so if you have any, it will be a great help for you.\n\n- this build can get MANY support perks that helps in PvE."
  },
  {
    "buildString": "51a1aa318i7e",
    "levelString": "11e8.7b7c4131373i3d3g3e7a777e5063-21f50-216-2i8-2ek697g7h7f7372506e-2ea-28w78797476-27i506r_2714506u-27a-2id50-614-2dk75-2ct-2aa7i506s_2a25506t-2ax7t",
    "level": 24,
    "HP": 255,
    "avatar": "VaultSuit",
    "name": "A Leveler",
    "class": "Small Gunner",
    "role": "Leveler, Pre-Reroll",
    "author": "Henry",
    "notes": "Levels faster than most, then gets re-rolled. You find a build you want, but it's a high level, so you use this leveler build to reach that level, then reroll this into the build you really want. Get Swift Learner asap w/Science 50. Read 10 Science books to help with this. Leveling methods vary, so leveling builds might be specific to a method. This one is standard, meant for combat leveling, but can craft gear and drugs too."
  },
  {
    "buildString": "51a12aa27iac",
    "levelString": "11e8.4231373i3d3g3e7b7c7e5063-22a50727369-22k506e-22f506r-228506d-22750-226-61450-2256u50-224-2i3516y55-2i7-28u7f7g7h5a_281p-2au5a_2a1y5a-2at-2ea5a_2e15747t-2fu5a_2f2651-2f451-2f451-2f4-27152-272557l7m7n-2ct7i",
    "level": 90,
    "HP": 255,
    "avatar": "VaultSuit",
    "name": "A Versatile Hi-DMG Minigunner",
    "class": "Big Gun Burster",
    "role": "PvE, Quester, Explorer, Combat Support",
    "author": "Henry",
    "notes": "Does max damage w/miniguns, solos some tougher quests including Gunrunners mutie cave, travels smoothly on the map, easily opens lockers, glides over bear traps, ususally heals himself, can lead 3 NPC Companion Followers, has best success repairing gear, and heroic Luck, but...very low PE/Sight."
  },
  {
    "buildString": "91a18a147i6a",
    "levelString": "11e8.7b7c44373i3d3e3g3150777e-24j6450697273-24i506e-244-2id7f7g7h506s-2i8-28u506t_281k-27376787950-614-27i5066-27d506c-272_2f1j56_2f1t-2er7l7m7n51-2e9-2d35174-2dd51-2db51-2d17a75-2cj51-2ca7i",
    "level": 35,
    "HP": 199,
    "avatar": "VaultSuit",
    "name": "Turbo Fast Miner",
    "class": "Close Combat/Melee",
    "role": "Miner",
    "author": "Henry",
    "notes": "If you want to mass-craft gear, and you want to mine ore as fast as possible, you might like to have this dedicated support character. He mines with 16 Acton Points on Jet wearing +Charisma armor, wielding a +CH melee weapon (Mega Power Fist preferred). Based on Turbo Miner build from wiki."
  },
  {
    "buildString": "69a119317i8c",
    "levelString": "ake8.4131373i3d3e3g52-2146353-2166953-2156e53-2156f53-2146r53-214-61453-2146u53-214-6135697969592919472737b7c7d7e8385-528a3a6a2a5a1a4-27z_2119_284o51",
    "level": 110,
    "HP": 307,
    "avatar": "CombatArmorMark2",
    "name": "Grenadier",
    "class": "Grenade Launcher Tank",
    "role": "Support",
    "author": "Powerpuff",
    "notes": "lvl 1 - rush gun runners, 10% boost to sg, then get all books.\n\nperks as you see.\n\nimplants from lvl 30, advanced from lvl 40"
  },
  {
    "buildString": "59a11851278c",
    "levelString": "age8.4131373i3d3e3g52-2146353-2166753-2156e53-2156d53-2156r53-214-61452-213516y-22753-22l6u5695969792919596979291-51y_222w-27z5a_282c_2i1372737b7c7d7ea3a6a2a5a1a4",
    "level": 110,
    "HP": 276,
    "avatar": "BridgekeepersRobes",
    "name": "A mixed burster",
    "class": "Burster",
    "role": "PvP",
    "author": "Powerpuff",
    "notes": "- lvl 1 gun runners 10% boost; all books (fa + sg); pump sg skill to 200%; perks taken in the order as shown; implants included\n\nfinal sight with full drug set = 41 .. PER can be lowered and LCK boosted - optional!\n\nEven more criticals can be swapped with toughness - optional!"
  },
  {
    "buildString": "51a44a527fac",
    "levelString": "9he8.7b4231373i3d3g3e50-22k63506972737c-22k506e-22a506r-22a50-2256b-2fk50-6147l-2fn7m7n506u-2iu506s7e-2ii56-2ic-2eu7g7h7f5a-2ef-2ct7i74_2a275a_2a1e-2875a_282i-2265a-2247t-27u5a-2fx5a-2fk5a-2fj-2778482",
    "level": 100,
    "HP": 262,
    "avatar": "CombatArmor",
    "name": "Pyromaniac Captain",
    "class": "Flame-Burst Team Leader",
    "role": "Squad Combat",
    "author": "Henry",
    "notes": "Team Leader build, uses Incinerators +Pyro perk for big fire dmg, leads 4 NPC human Companion followers @Level 100, but gets these @Level 70: 3 NPC human companions, max Lockpick, glide over locker traps, max First Aid, Repair your own gear, fast map travel, Doc 100, multiple crafting professions. Tough and gets +7 bonus Hit Points via \"Mystery Boosts\". Give LSWs to your followers and have them run combat support from a distance."
  }
]

export default presets;