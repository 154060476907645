import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Default from '../views/desktop/Default.vue'
import LinkedBuild from '../views/desktop/LinkedBuild.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Default',
    component: Default
  },
  {
    path: '/:b',
    name: 'build',
    component: LinkedBuild,
  },
  {
    path: '/:b/:l',
    name: 'level',
    component: LinkedBuild
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
