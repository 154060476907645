<template>
  <div class="container">
    <div class="form-wrapper">
      <form @submit.prevent="saveBuild">
        <label for="name-input" :class="inputName.length ? isNameUnique(inputName.trim()) ? 'valid-label' : 'overwrite-label' : 'required-label'">
          {{ inputName.length ? isNameUnique(inputName) ? 'Name' : 'Name found. Will update save.' : 'Name (Required)' }}
        </label>
        <input autocomplete="off" id="name-input" spellcheck="false" type="text" placeholder="Character name..." v-model="inputName" required @focusout="loadExistingSave" />
        <label for="class-input">Class (Optional)</label>
        <input autocomplete="off" id="class-input" spellcheck="false" type="text" placeholder="Class (e.g. Burster, Sniper, Melee, etc.)" v-model="inputClass" />
        <label for="role-input">Role (Optional)</label>
        <input autocomplete="off" id="role-input" spellcheck="false" type="text" placeholder="Role (e.g. Main, PvP, Support, RP, etc.)" v-model="inputRole" />
        <label for="class-input">Author (Optional)</label>
        <input autocomplete="off" id="author-input" spellcheck="false" type="text" placeholder="Author..." v-model="inputAuthor" />
        <label for="notes-input">Notes (Optional)</label>
        <textarea id="notes-input" name="notes" rows="5" spellcheck="false" placeholder="Add notes here..." v-model="inputNotes"></textarea>
        <button :class="'save-button ' + (inputName.length ? isNameUnique(inputName.trim()) ? '' : 'update-save' : 'disabled')">
          {{ inputName.trim().length ? isNameUnique(inputName.trim()) ? 'CREATE NEW SAVE' : 'UPDATE EXISTING SAVE' : 'NAME IS REQUIRED' }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { defineComponent, ref } from "vue"
export default defineComponent({
 name: "CharacterTable",
 components: {},
 setup() {
    const store = useStore()
    const inputName = ref('')
    const inputClass = ref('')
    const inputRole = ref('')
    const inputAuthor = ref('')
    const inputNotes = ref('')
    const newSave = ref(true)

    const libraryKeys = Object.keys(store.state.library) || []

    const isNameUnique = (name) => {
      return !libraryKeys.includes(name.trim())
    }

    const saveBuild = () => {
      const save = {
        name: inputName.value.trim(),
        class: inputClass.value.trim(),
        role: inputRole.value.trim(),
        author: inputAuthor.value.trim(),
        notes: inputNotes.value.trim()
      }
      store.dispatch('saveCharacter', save)
    }

    const loadExistingSave = ()  => {
      const unique = isNameUnique(inputName.value.trim())
      if (!unique) {
        newSave.value = false
        const save = store.state.library[inputName.value.trim()]
        if (save) {
            inputClass.value = save.class
            inputRole.value = save.role
            inputAuthor.value = save.author
            inputNotes.value = save.notes
        }
      } else {
        newSave.value = true
      }
    }
    
    return {
      state: store.state,
      inputName,
      inputClass,
      inputRole,
      inputAuthor,
      inputNotes,
      libraryKeys,
      isNameUnique,
      saveBuild,
      loadExistingSave,
   }
 },
})
</script>

<style scoped>
.container {
  width: 100%;
  /* max-height: 110px; */
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-wrapper {
  width: 90%;
  text-align: left;
  position: relative;
}
.required-label {
  color:#dcb6b6;
}
.valid-label {
  color: #dbdb34;
}
.overwrite-label {
  color: #e3af4f;
}
textarea, input {
  width: 96%;
  border-radius: 3px;
  font-weight: 600;
  text-shadow: 1px 1px 1px black;
  text-align: left;
  background-color: rgba(215, 196, 155, 0.222);
  padding: 0px 5px 0px 5px;
  /* color: #dbdb34; */
  color: inherit;
  border: 1px solid #f6d84290;
  outline: none;

}
textarea {
  resize: none;
  height: 200px;
  font-size: 14px;
  padding-top: 3px;
}
input {
  height: 30px;
  line-height: 20px;
  margin-bottom: 10px;
}
input::placeholder {
  color: rgba(255, 255, 255, 0.531);
}
textarea::placeholder {
  color: rgba(255, 255, 255, 0.531);
}
input:focus, textarea:focus {
  border: 1px solid #99f185;
}
label {
  width: 100%;
  text-align: left;
  line-height: 20px;
  color: #dbdb34;
  text-shadow: 1px 1px 1px black;
}
.input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.save-button {
  position: relative;
  cursor: pointer;
  outline: none;
  width: 100%;
  height: 70px;
  color: white;
  letter-spacing: 1px;
  text-shadow: 1px 1px black;
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
  font-size: 22px;
  border-radius: 3px;
  border: 1px solid white;
  background-color: rgba(80, 168, 80, 0.445);
  margin: 15px 0px 5px 0px;
}
.save-button:hover {
  background-color: rgba(80, 168, 80, 0.884);
}
.disabled {
  background-color: rgba(85, 90, 85, 0.445);
  cursor: not-allowed;
}
.disabled:hover {
  background-color: rgba(85, 90, 85, 0.445);
}
.update-save {
  background-color: rgba(255, 230, 0, 0.461);
}
.update-save:hover {
  background-color: rgba(255, 230, 0, 0.561);
}
</style>