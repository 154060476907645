export default {
  AdvancedDR: {
    // ! HEAD
    head: {
      NoDR: function (build: any) {
        const baseValue =
          1 * (build.EquippedGear.head.baseStats.NoDR || 0) +
          1 * (build.EquippedGear.head.bonusStats?.NoDR || 0);
        return baseValue;
      },
      NoDT: function (build: any) {
        const baseValue =
          1 * (build.EquippedGear.head.baseStats.NoDT || 0) +
          1 * (build.EquippedGear.head.bonusStats?.NoDT || 0);
        return baseValue;
      },
      LaDR: function (build: any) {
        const baseValue =
          1 * (build.EquippedGear.head.baseStats.LaDR || 0) +
          1 * (build.EquippedGear.head.bonusStats?.LaDR || 0);
        return baseValue;
      },
      LaDT: function (build: any) {
        const baseValue =
          1 * (build.EquippedGear.head.baseStats.LaDT || 0) +
          1 * (build.EquippedGear.head.bonusStats?.LaDT || 0);
        return baseValue;
      },
      FiDR: function (build: any) {
        const baseValue =
          1 * (build.EquippedGear.head.baseStats.FiDR || 0) +
          1 * (build.EquippedGear.head.bonusStats?.FiDR || 0);
        return baseValue;
      },
      FiDT: function (build: any) {
        const baseValue =
          1 * (build.EquippedGear.head.baseStats.FiDT || 0) +
          1 * (build.EquippedGear.head.bonusStats?.FiDT || 0);
        return baseValue;
      },
      PlDR: function (build: any) {
        const baseValue =
          1 * (build.EquippedGear.head.baseStats.PlDR || 0) +
          1 * (build.EquippedGear.head.bonusStats?.PlDR || 0);
        return baseValue;
      },
      PlDT: function (build: any) {
        const baseValue =
          1 * (build.EquippedGear.head.baseStats.PlDT || 0) +
          1 * (build.EquippedGear.head.bonusStats?.PlDT || 0);
        return baseValue;
      },
      ExDR: function (build: any) {
        const baseValue =
          1 * (build.EquippedGear.head.baseStats.ExDR || 0) +
          1 * (build.EquippedGear.head.bonusStats?.ExDR || 0);
        return baseValue;
      },
      ExDT: function (build: any) {
        const baseValue =
          1 * (build.EquippedGear.head.baseStats.ExDT || 0) +
          1 * (build.EquippedGear.head.bonusStats?.ExDT || 0);
        return baseValue;
      },
      ElDR: function (build: any) {
        const baseValue =
          1 * (build.EquippedGear.head.baseStats.ElDR || 0) +
          1 * (build.EquippedGear.head.bonusStats?.ElDR || 0);
        return baseValue;
      },
      ElDT: function (build: any) {
        const baseValue =
          1 * (build.EquippedGear.head.baseStats.ElDT || 0) +
          1 * (build.EquippedGear.head.bonusStats?.ElDT || 0);
        return baseValue;
      },
    },
    // ! BODY
    body: {
      NoDR: function (build: any) {
        let baseValue =
          -10 * build.Traits.Kamikaze.tagged +
          -15 * build.Drugs.Jet.tagged +
          15 * build.Drugs.Psycho.tagged +
          5 * build.Drugs.Beer.tagged +
          5 * build.Perks.Toughness.tagged +
          10 * build.Perks.EvenTougher.tagged +
          2 * build.CombatImplants.Dermal1.tagged +
          2 * build.CombatImplants.Dermal2.tagged +
          5 * (build.MysteryBoosts.BecomeAMutant?.tagged || 0) +
          1 * (build.EquippedGear.body.baseStats.NoDR || 0) +
          1 * (build.EquippedGear.body.bonusStats?.NoDR || 0);

        if (baseValue > 90) {
          baseValue = 90
        }
        return baseValue;
      },
      NoDT: function (build: any) {
        const baseValue =
          1 * build.Perks.Toughness.tagged +
          3 * build.Perks.EvenTougher.tagged +
          1 * build.CombatImplants.Dermal2.tagged +
          1 * (build.EquippedGear.body.baseStats.NoDT || 0) +
          1 * (build.EquippedGear.body.bonusStats?.NoDT || 0);

        return baseValue;
      },
      LaDR: function (build: any) {
        let baseValue =
          2 * build.CombatImplants.Phoenix1.tagged +
          2 * build.CombatImplants.Phoenix2.tagged +
          3 * build.MysteryBoosts.FatherTully.tagged +
          5 * build.Perks.Toughness.tagged +
          5 * (build.MysteryBoosts.BecomeAMutant?.tagged || 0) +
          1 * (build.EquippedGear.body.baseStats.LaDR || 0) +
          1 * (build.EquippedGear.body.bonusStats?.LaDR || 0);

        if (baseValue > 90) {
          baseValue = 90
        }
        return baseValue
      },
      LaDT: function (build: any) {
        const baseValue =
          1 * build.CombatImplants.Phoenix2.tagged +
          1 * build.Perks.Toughness.tagged +
          1 * (build.EquippedGear.body.baseStats.LaDT || 0) +
          1 * (build.EquippedGear.body.bonusStats?.LaDT || 0);
        return baseValue
      },
      FiDR: function (build: any) {
        let baseValue =
          2 * build.CombatImplants.Phoenix1.tagged +
          2 * build.CombatImplants.Phoenix2.tagged +
          5 * build.Perks.Toughness.tagged +
          5 * (build.MysteryBoosts.BecomeAMutant?.tagged || 0) +
          1 * (build.EquippedGear.body.baseStats.FireDR || 0) +
          1 * (build.EquippedGear.body.bonusStats?.FireDR || 0);

        if (baseValue > 90) {
          baseValue = 90
        }
        return baseValue
      },
      FiDT: function (build: any) {
        const baseValue =
          1 * build.CombatImplants.Phoenix2.tagged +
          1 * build.Perks.Toughness.tagged +
          1 * (build.EquippedGear.body.baseStats.FireDT || 0) +
          1 * (build.EquippedGear.body.bonusStats?.FireDT || 0);

        return baseValue
      },
      PlDR: function (build: any) {
        let baseValue =
          2 * build.CombatImplants.Phoenix1.tagged +
          2 * build.CombatImplants.Phoenix2.tagged +
          5 * build.Perks.Toughness.tagged +
          5 * (build.MysteryBoosts.BecomeAMutant?.tagged || 0) +
          1 * (build.EquippedGear.body.baseStats.PlDR || 0) +
          1 * (build.EquippedGear.body.bonusStats?.PlDR || 0);
        if (baseValue > 90) {
          baseValue = 90
        }
        return baseValue
      },
      PlDT: function (build: any) {
        const baseValue =
          1 * build.CombatImplants.Phoenix2.tagged +
          1 * build.Perks.Toughness.tagged +
          1 * (build.EquippedGear.body.baseStats.PlDT || 0) +
          1 * (build.EquippedGear.body.bonusStats?.PlDT || 0);
        return baseValue
      },
      ExDR: function (build: any) {
        let baseValue =
          2 * build.CombatImplants.Dermal1.tagged +
          2 * build.CombatImplants.Dermal2.tagged +
          5 * build.Perks.Toughness.tagged +
          5 * (build.MysteryBoosts.BecomeAMutant?.tagged || 0) +
          1 * (build.EquippedGear.body.baseStats.ExDR || 0) +
          1 * (build.EquippedGear.body.bonusStats?.ExDR || 0);

        if (baseValue > 90) {
          baseValue = 90
        }
        return baseValue
      },
      ExDT: function (build: any) {
        const baseValue =
          1 * build.CombatImplants.Dermal2.tagged +
          1 * build.Perks.Toughness.tagged +
          1 * (build.EquippedGear.body.baseStats.ExDT || 0) +
          1 * (build.EquippedGear.body.bonusStats?.ExDT || 0);

        return baseValue
      },
      ElDR: function (build: any) {
        let baseValue = 5 * build.Perks.Toughness.tagged +
          5 * (build.MysteryBoosts.BecomeAMutant?.tagged || 0) +
          1 * (build.EquippedGear.body.baseStats.ElDR || 0) +
          1 * (build.EquippedGear.body.bonusStats?.ElDR || 0);

        if (baseValue > 90) {
          baseValue = 90
        }
        return baseValue
      },
      ElDT: function (build: any) {
        const baseValue = 1 * build.Perks.Toughness.tagged +
          1 * (build.EquippedGear.body.baseStats.ElDT || 0) +
          1 * (build.EquippedGear.body.bonusStats?.ElDT || 0);
        return baseValue
      },
    }
  },
  CharStats: {
    HitPoints: {
      updateStat: function (build: any) {
        const baseValue =
          55 +
          1 * build.SPECIAL.ST.value +
          2 * build.SPECIAL.EN.value +
          1 * build.CharStats.HitPoints.gainedValue +
          30 * build.Perks.Lifegiver1.tagged +
          30 * build.Perks.Lifegiver2.tagged +
          30 * build.Perks.Lifegiver3.tagged +
          4 * build.Perks.GainEndurance.tagged +
          2 * build.Perks.GainStrength.tagged +
          10 * build.CombatImplants.Nemean1.tagged +
          10 * build.CombatImplants.Nemean2.tagged +
          2 * build.MysteryBoosts.HubChef.tagged +
          2 * build.MysteryBoosts.FatherTully.tagged +
          5 * build.MysteryBoosts.Mom.tagged +
          50 * (build.MysteryBoosts.BecomeAMutant?.tagged || 0) +
          2 * (build.SupportPerks.AlcoholRaisedHP1?.tagged || 0) +
          2 * (build.SupportPerks.AlcoholRaisedHP2?.tagged || 0)
        return baseValue
      }
    },
    ArmorClass: {
      updateStat: function (build: any) {
        let baseValue =
          3 * build.SPECIAL.AG.value +
          (3 * build.SPECIAL.AG.value) * build.Perks.Livewire.tagged
        if (build.EquippedGear.body?.baseStats) {
          baseValue += build.EquippedGear.body.baseStats.AC
        }
        return baseValue
      }
    },
    ActionPoints: {
      updateStat: function (build: any) {
        const baseValue =
          5 +
          1 * Math.floor((build.SPECIAL.AG.onDrugs) / 2)
          - 2 * build.Traits.Bruiser.tagged +
          2 * build.Drugs.Jet.tagged +
          1 * build.Perks.ActionBoy1.tagged +
          1 * build.Perks.ActionBoy2.tagged +
          1 * (build.EquippedGear.body.bonusStats?.ActionPoints || 0);

        return baseValue
      }
    },
    CarryWeight: {
      updateStat: function (build: any) {
        // TODO this formula needs verifying and probably modifications
        const baseValue =
          1 * Math.floor(((build.SPECIAL.ST.onDrugs * 25 + 25) / 2.2) + 20 - 0.5) *
          (build.SupportPerks.PackRat.tagged ? 1.33 : 1) +
          22 * build.SupportPerks.StrongBack.tagged +
          1 * (build.EquippedGear.body.bonusStats?.CarryWeight || 0)
        return baseValue;
      }
    },
    MeleeDamage: {
      updateStat: function (build: any) {
        const ST = build.SPECIAL.ST.value
        const bruiser = build.Traits.Bruiser.tagged
        let baseValue = 0
        if (bruiser) {
          baseValue += 2 * (Math.floor(ST / 7) * ((ST - 6) + 1)) + Math.floor((ST % 7) / ST)
        } else {
          baseValue += Math.floor(ST / 7) * (ST - 6) + 1
        }
        baseValue +=
          10 * build.Perks.CloseCombatMaster.tagged +
          4 * build.MysteryBoosts.Francis.tagged
        return baseValue;
      }
    },
    DamageResistance: {
      updateStat: function (build: any) {
        let baseValue =
          -10 * build.Traits.Kamikaze.tagged +
          -15 * build.Drugs.Jet.tagged +
          15 * build.Drugs.Psycho.tagged +
          5 * build.Drugs.Beer.tagged +
          5 * build.Perks.Toughness.tagged +
          10 * build.Perks.EvenTougher.tagged +
          2 * build.CombatImplants.Dermal1.tagged +
          2 * build.CombatImplants.Dermal2.tagged +
          5 * (build.MysteryBoosts.BecomeAMutant?.tagged || 0) +
          1 * (build.EquippedGear.body.bonusStats?.NoDR || 0);
        if (baseValue > 90) {
          baseValue = 90
        }
        return baseValue;
      }
    },
    PoisonResistance: {
      updateStat: function (build: any) {
        const baseValue =
          3 * build.SPECIAL.EN.value +
          20 * build.SupportPerks.RadResistance.tagged +
          30 * build.SupportPerks.Snakeater.tagged +
          15 * (build.MysteryBoosts.BecomeAMutant?.tagged || 0) +
          1 * (build.EquippedGear.body.bonusStats?.PoisonResistance || 0);
        return baseValue;
      }
    },
    RadResistance: {
      updateStat: function (build: any) {
        const baseValue =
          2 * build.SPECIAL.EN.value +
          30 * build.SupportPerks.RadResistance.tagged +
          20 * build.SupportPerks.Snakeater.tagged +
          25 * build.Drugs.RadX.tagged +
          15 * (build.MysteryBoosts.BecomeAMutant?.tagged || 0) +
          1 * build.EquippedGear.body.baseStats.RadResistance +
          1 * (build.EquippedGear.body.bonusStats?.RadResistance || 0);
        return baseValue;
      }
    },
    Sequence: {
      updateStat: function (build: any) {
        const baseValue =
          2 +
          2 * build.SPECIAL.PE.value
        return baseValue;
      }
    },
    HealingRate: {
      updateStat: function (build: any) {
        const baseValue =
          1 * Math.floor(build.SPECIAL.EN.value / 2) + 7 +
          5 * build.SupportPerks.FasterHealing.tagged +
          1 * (build.EquippedGear.body.bonusStats?.HealingRate || 0)
        return baseValue;
      }
    },
    CriticalChance: {
      updateStat: function (build: any) {
        const baseValue =
          1 * build.SPECIAL.LK.onDrugs +
          10 * build.Traits.Finesse.tagged +
          5 * build.Perks.MoreCritical.tagged +
          10 * build.Perks.EvenMoreCriticals.tagged +
          15 * build.Perks.CloseCombatMaster.tagged
        return baseValue;
      }
    },
    PartyPoints: {
      updateStat: function (build: any) {
        const baseValue =
          10 * build.SPECIAL.CH.onDrugs +
          50 * build.Traits.GoodNatured.tagged +
          Math.floor(build.Skills.Speech.value / 3) +
          50 * build.SupportPerks.MagneticPersonality.tagged +
          20 * build.Perks.GainCharisma.tagged;
        return baseValue;
      }
    },
    MaximumPerks: {
      updateStat: function (build: any) {
        return 8 - 2 * build.Traits.Skilled.tagged
      }
    },
    SkillPointsPerLevel: {
      updateStat: function (build: any) {
        const baseValue =
          5 +
          2 * build.SPECIAL.IN.value +
          5 * build.Traits.Skilled.tagged +
          2 * build.SupportPerks.Educated.tagged;
        return baseValue;
      }
    },
    HitPointsPerLevel: {
      updateStat: function (build: any) {
        const EN = build.SPECIAL.EN.value
        if (EN % 2) {
          switch (EN) {
            case 1:
              return 1
            case 3:
              return 1.5
            case 5:
              return 2
            case 7:
              return 2.5
            case 9:
              return 3
          }
        } else {
          return EN / 2
        }
      }
    },
    SightRange: {
      updateStat: function (build: any) {
        const baseValue =
          20 +
          3 * build.SPECIAL.PE.onDrugs +
          6 * build.Perks.Sharpshooter.tagged;
        return baseValue;
      }
    },
  },
  SPECIAL: {
    ST: {
      updateStat: function (build: any) {
        let statValue =
          1 * build.SPECIAL.ST.baseValue +
          1 * build.SPECIAL.ST.implanted +
          2 * build.Perks.GainStrength.tagged;
        if (statValue > 10) {
          statValue = 10;
        } else if (statValue < 1) {
          statValue = 1;
        }

        return statValue;
      },
      onDrugs: function (build: any) {
        let drugValue = this.updateStat(build) +
          2 * build.Drugs.Buffout.tagged +
          1 * build.Drugs.Psycho.tagged +
          1 * (build.EquippedGear.body.bonusStats?.ST || 0) +
          -2 * build.Drugs.Jet.tagged;
        if (drugValue > 10) {
          drugValue = 10;
        } else if (drugValue < 1) {
          drugValue = 1;
        }
        return drugValue;
      }
    },
    PE: {
      updateStat: function (build: any) {
        let statValue =
          1 * build.SPECIAL.PE.baseValue +
          1 * build.SPECIAL.PE.implanted +
          2 * build.Perks.GainPerception.tagged;

        if (statValue > 10) {
          statValue = 10;
        } else if (statValue < 1) {
          statValue = 1;
        }

        return statValue;
      },
      onDrugs: function (build: any) {
        let drugValue = this.updateStat(build) +
          1 * build.Drugs.Cigarettes.tagged +
          -2 * build.Drugs.Psycho.tagged +
          1 * (build.EquippedGear.body.bonusStats?.PE || 0) +
          -2 * build.Drugs.Beer.tagged;

        if (build.EquippedGear.head?.name === 'Spectacles') {
          const statValue = this.updateStat(build)
          const bonus = statValue < 4 ? 4 - statValue : 0
          drugValue += bonus
        }

        if (drugValue > 10) {
          drugValue = 10;
        } else if (drugValue < 1) {
          drugValue = 1;
        }
        return drugValue;
      }
    },
    EN: {
      updateStat: function (build: any) {
        let statValue =
          1 * build.SPECIAL.EN.baseValue +
          1 * build.SPECIAL.EN.implanted +
          2 * build.Perks.GainEndurance.tagged;

        if (statValue > 10) {
          statValue = 10;
        } else if (statValue < 1) {
          statValue = 1;
        }

        return statValue;
      },
      onDrugs: function (build: any) {
        let drugValue = this.updateStat(build) +
          1 * build.Drugs.Psycho.tagged +
          1 * (build.EquippedGear.body.bonusStats?.EN || 0) +
          2 * build.Drugs.Buffout.tagged;
        if (drugValue > 10) {
          drugValue = 10;
        } else if (drugValue < 1) {
          drugValue = 1;
        }
        return drugValue;
      }
    },
    CH: {
      updateStat: function (build: any) {
        let statValue =
          1 * build.SPECIAL.CH.baseValue +
          1 * build.SPECIAL.CH.implanted +
          2 * build.Perks.GainCharisma.tagged;

        if (statValue > 10) {
          statValue = 10;
        } else if (statValue < 1) {
          statValue = 1;
        }

        return statValue;
      },
      onDrugs: function (build: any) {
        let drugValue = this.updateStat(build) +
          1 * (build.EquippedGear.body.bonusStats?.CH || 0) +
          1 * (build.EquippedGear.head.bonusStats?.CH || 0) +
          2 * build.Drugs.Mentats.tagged +
          1 * build.Drugs.Beer.tagged +
          -1 * build.Drugs.Cigarettes.tagged +
          -1 * build.Drugs.Jet.tagged;

        if (drugValue > 10) {
          drugValue = 10;
        } else if (drugValue < 1) {
          drugValue = 1;
        }
        return drugValue;
      }
    },
    IN: {
      updateStat: function (build: any) {
        let statValue =
          1 * build.SPECIAL.IN.baseValue +
          1 * build.SPECIAL.IN.implanted +
          2 * build.Perks.GainIntelligence.tagged;

        if (statValue > 10) {
          statValue = 10;
        } else if (statValue < 1) {
          statValue = 1;
        }

        return statValue;
      },
      onDrugs: function (build: any) {
        let drugValue = this.updateStat(build) +
          1 * (build.EquippedGear.body.bonusStats?.IN || 0) +
          2 * build.Drugs.Mentats.tagged;

        if (drugValue > 10) {
          drugValue = 10;
        } else if (drugValue < 1) {
          drugValue = 1;
        }
        return drugValue;
      }
    },
    AG: {
      updateStat: function (build: any) {
        let statValue =
          1 * build.SPECIAL.AG.baseValue +
          1 * build.SPECIAL.AG.implanted +
          2 * build.Perks.GainAgility.tagged;

        if (statValue > 10) {
          statValue = 10;
        } else if (statValue < 1) {
          statValue = 1;
        }

        return statValue;
      },
      onDrugs: function (build: any) {
        let drugValue = this.updateStat(build) +
          1 * build.Drugs.NukaCola.tagged +
          1 * (build.EquippedGear.body.bonusStats?.AG || 0) +
          -1 * build.Drugs.Buffout.tagged;

        if (drugValue > 10) {
          drugValue = 10;
        } else if (drugValue < 1) {
          drugValue = 1;
        }
        return drugValue;
      }
    },
    LK: {
      updateStat: function (build: any) {
        let statValue =
          1 * build.SPECIAL.LK.baseValue +
          1 * build.SPECIAL.LK.implanted +
          2 * build.Perks.GainLuck.tagged;

        if (statValue > 10) {
          statValue = 10;
        } else if (statValue < 1) {
          statValue = 1;
        }

        return statValue;
      },
      onDrugs: function (build: any) {
        let drugValue = this.updateStat(build) +
          1 * (build.EquippedGear.body.bonusStats?.LK || 0);

        if (drugValue > 10) {
          drugValue = 10;
        } else if (drugValue < 1) {
          drugValue = 1;
        }
        return drugValue;
      }
    },
    PL: {
      updateStat: function (build: any) {
        return build.SPECIAL.PL.baseValue;
      },
      onDrugs: function () {
        return 0
      }
    },
  },
  Skills: {
    SmallGuns: {
      updateStat: function (build: any) {
        const baseValue =
          5 +
          20 * build.Skills.SmallGuns.tagged +
          10 * build.Boosts.SmallGuns.tagged +
          4 * build.SPECIAL.AG.baseValue;

        const value = baseValue +
          build.Skills.SmallGuns.gainedSkillValue +
          build.Skills.SmallGuns.stepSkillValue +
          build.Books.SmallGuns.gainedSkillValue;
        if (value > build.Skills.SmallGuns.maxValue) {
          return build.Skills.SmallGuns.maxValue
        } else {
          return value
        }
      }
    },
    BigGuns: {
      updateStat: function (build: any) {
        const baseValue =
          0 +
          20 * build.Skills.BigGuns.tagged +
          10 * build.Boosts.BigGuns.tagged +
          2 * build.SPECIAL.AG.baseValue;

        const value = baseValue +
          build.Skills.BigGuns.gainedSkillValue +
          build.Skills.BigGuns.stepSkillValue;
        if (value > build.Skills.BigGuns.maxValue) {
          return build.Skills.BigGuns.maxValue
        } else {
          return value
        }
      }
    },
    EnergyWeapons: {
      updateStat: function (build: any) {
        const baseValue =
          0 +
          20 * build.Skills.EnergyWeapons.tagged +
          10 * build.Boosts.EnergyWeapons.tagged +
          2 * build.SPECIAL.AG.baseValue;

        const value = baseValue +
          build.Skills.EnergyWeapons.gainedSkillValue +
          build.Skills.EnergyWeapons.stepSkillValue;
        if (value > build.Skills.EnergyWeapons.maxValue) {
          return build.Skills.EnergyWeapons.maxValue
        } else {
          return value
        }
      }
    },
    CloseCombat: {
      updateStat: function (build: any) {
        const baseValue =
          30 +
          20 * build.Skills.CloseCombat.tagged +
          10 * build.Boosts.CloseCombat.tagged +
          15 * build.MysteryBoosts.SadFarmer.tagged +
          2 * build.SPECIAL.ST.baseValue +
          2 * build.SPECIAL.AG.baseValue;

        const value = baseValue +
          build.Skills.CloseCombat.gainedSkillValue +
          build.Skills.CloseCombat.stepSkillValue;
        if (value > build.Skills.CloseCombat.maxValue) {
          return build.Skills.CloseCombat.maxValue
        } else {
          return value
        }
      }
    },
    Scavenging: {
      updateStat: function () {
        return 0
      }
    },
    Throwing: {
      updateStat: function (build: any) {
        const baseValue =
          0 +
          20 * build.Skills.Throwing.tagged +
          10 * build.Boosts.Throwing.tagged +
          4 * build.SPECIAL.AG.baseValue;

        const value = baseValue +
          build.Skills.Throwing.gainedSkillValue +
          build.Skills.Throwing.stepSkillValue;
        if (value > build.Skills.Throwing.maxValue) {
          return build.Skills.Throwing.maxValue
        } else {
          return value
        }
      }
    },
    FirstAid: {
      updateStat: function (build: any) {
        const baseValue =
          0 +
          20 * build.Skills.FirstAid.tagged +
          2 * build.SPECIAL.PE.baseValue +
          2 * (build.SPECIAL.IN.baseValue + build.Traits.Bonehead.tagged); // Temp fix for the Bonehead bug.

        const value = baseValue +
          build.Skills.FirstAid.gainedSkillValue +
          build.Skills.FirstAid.stepSkillValue +
          build.Books.FirstAid.gainedSkillValue;
        if (value > build.Skills.FirstAid.maxValue) {
          return build.Skills.FirstAid.maxValue
        } else {
          return value
        }
      }
    },
    Doctor: {
      updateStat: function (build: any) {
        const baseValue =
          5 +
          20 * build.Skills.Doctor.tagged +
          1 * build.SPECIAL.PE.baseValue +
          1 * (build.SPECIAL.IN.baseValue + build.Traits.Bonehead.tagged); // Temp fix for the Bonehead bug.

        const value = baseValue +
          build.Skills.Doctor.gainedSkillValue +
          build.Skills.Doctor.stepSkillValue;
        if (value > build.Skills.Doctor.maxValue) {
          return build.Skills.Doctor.maxValue
        } else {
          return value
        }
      }
    },
    Sneak: {
      updateStat: function (build: any) {
        const baseValue =
          5 +
          20 * build.Skills.Sneak.tagged +
          3 * build.SPECIAL.AG.baseValue;

        const value = baseValue +
          build.Skills.Sneak.gainedSkillValue +
          build.Skills.Sneak.stepSkillValue;
        if (value > build.Skills.Sneak.maxValue) {
          return build.Skills.Sneak.maxValue
        } else {
          return value
        }
      }
    },
    Lockpick: {
      updateStat: function (build: any) {
        const baseValue =
          10 +
          20 * build.Skills.Lockpick.tagged +
          1 * build.SPECIAL.PE.baseValue +
          1 * build.SPECIAL.AG.baseValue;

        const value = baseValue +
          build.Skills.Lockpick.gainedSkillValue +
          build.Skills.Lockpick.stepSkillValue;

        if (value > build.Skills.Lockpick.maxValue) {
          return build.Skills.Lockpick.maxValue
        } else {
          return value
        }
      }
    },
    Steal: {
      updateStat: function (build: any) {
        const baseValue =
          0 +
          20 * build.Skills.Steal.tagged +
          3 * build.SPECIAL.AG.baseValue;

        const value = baseValue +
          build.Skills.Steal.gainedSkillValue +
          build.Skills.Steal.stepSkillValue;
        if (value > build.Skills.Steal.maxValue) {
          return build.Skills.Steal.maxValue
        } else {
          return value
        }
      }
    },
    Traps: {
      updateStat: function (build: any) {
        const baseValue =
          10 +
          20 * build.Skills.Traps.tagged +
          1 * build.SPECIAL.PE.baseValue +
          1 * build.SPECIAL.AG.baseValue;

        const value = baseValue +
          build.Skills.Traps.gainedSkillValue +
          build.Skills.Traps.stepSkillValue;
        if (value > build.Skills.Traps.maxValue) {
          return build.Skills.Traps.maxValue
        } else {
          return value
        }
      }
    },
    Science: {
      updateStat: function (build: any) {
        const baseValue =
          0 +
          20 * build.Skills.Science.tagged +
          4 * (build.SPECIAL.IN.baseValue + build.Traits.Bonehead.tagged); // Temp fix for the Bonehead bug.

        const value = baseValue +
          build.Skills.Science.gainedSkillValue +
          build.Skills.Science.stepSkillValue +
          build.Books.Science.gainedSkillValue;
        if (value > build.Skills.Science.maxValue) {
          return build.Skills.Science.maxValue
        } else {
          return value
        }
      }
    },
    Repair: {
      updateStat: function (build: any) {
        const baseValue =
          0 +
          20 * build.Skills.Repair.tagged +
          3 * (build.SPECIAL.IN.baseValue + build.Traits.Bonehead.tagged); // Temp fix for the Bonehead bug.

        const value = baseValue +
          build.Skills.Repair.gainedSkillValue +
          build.Skills.Repair.stepSkillValue +
          build.Books.Repair.gainedSkillValue;
        if (value > build.Skills.Repair.maxValue) {
          return build.Skills.Repair.maxValue
        } else {
          return value
        }
      }
    },
    Speech: {
      updateStat: function (build: any) {
        const baseValue =
          0 +
          20 * build.Skills.Speech.tagged +
          5 * build.SPECIAL.CH.baseValue;

        const value = baseValue +
          build.Skills.Speech.gainedSkillValue +
          build.Skills.Speech.stepSkillValue;
        if (value > build.Skills.Speech.maxValue) {
          return build.Skills.Speech.maxValue
        } else {
          return value
        }
      }
    },
    Barter: {
      updateStat: function (build: any) {
        const baseValue =
          0 +
          20 * build.Skills.Barter.tagged +
          4 * build.SPECIAL.CH.baseValue;

        const value = baseValue +
          build.Skills.Barter.gainedSkillValue +
          build.Skills.Barter.stepSkillValue +
          build.Books.Barter.gainedSkillValue;
        if (value > build.Skills.Barter.maxValue) {
          return build.Skills.Barter.maxValue
        } else {
          return value
        }
      }
    },
    Gambling: {
      updateStat: function (build: any) {
        const baseValue =
          0 +
          20 * build.Skills.Gambling.tagged +
          13 * build.MysteryBoosts.RaidersDie.tagged +
          5 * build.SPECIAL.LK.baseValue;

        const value = baseValue +
          build.Skills.Gambling.gainedSkillValue +
          build.Skills.Gambling.stepSkillValue;
        if (value > build.Skills.Gambling.maxValue) {
          return build.Skills.Gambling.maxValue
        } else {
          return value
        }
      }
    },
    Outdoorsman: {
      updateStat: function (build: any) {
        const baseValue =
          0 +
          20 * build.Skills.Outdoorsman.tagged +
          2 * build.SPECIAL.EN.baseValue +
          2 * (build.SPECIAL.IN.baseValue + build.Traits.Bonehead.tagged); // Temp fix for the Bonehead bug.

        const value = baseValue +
          build.Skills.Outdoorsman.gainedSkillValue +
          build.Skills.Outdoorsman.stepSkillValue +
          build.Books.Outdoorsman.gainedSkillValue;
        if (value > build.Skills.Outdoorsman.maxValue) {
          return build.Skills.Outdoorsman.maxValue
        } else {
          return value
        }
      }
    },
  },
  Traits: {
    FastMetabolism: {
      name: 'FastMetabolism',
      tagged: false,
    },
    Bruiser: {
      name: 'Bruiser',
      tagged: false,
    },
    OneHander: {
      name: 'OneHander',
      tagged: false,
    },
    Finesse: {
      name: 'Finesse',
      tagged: false,
    },
    Kamikaze: {
      name: 'Kamikaze',
      tagged: false,
    },
    HeavyHanded: {
      name: 'HeavyHanded',
      tagged: false,
    },
    FastShot: {
      name: 'FastShot',
      tagged: false,
    },
    BloodyMess: {
      name: 'BloodyMess',
      tagged: false,
    },
    Jinxed: {
      name: 'Jinxed',
      tagged: false,
    },
    GoodNatured: {
      name: 'GoodNatured',
      tagged: false,
    },
    ChemReliant: {
      name: 'ChemReliant',
      tagged: false,
    },
    Bonehead: {
      name: 'Bonehead',
      tagged: false,
    },
    Skilled: {
      name: 'Skilled',
      tagged: false,
    },
    Loner: {
      name: 'Loner',
      tagged: false,
    },
  },
  Perks: {
    MoreCritical: {
      updateStat: function (build: any) {
        const SG = build.Skills.SmallGuns.value
        const BG = build.Skills.BigGuns.value
        const EW = build.Skills.EnergyWeapons.value
        const CC = build.Skills.CloseCombat.value
        const TH = build.Skills.Throwing.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 3 && (SG >= 100 || BG >= 100 || EW >= 100 || CC >= 100 || TH >= 100)) {
          return true
        } else {
          return false
        }
      }
    },
    QuickPockets: {
      updateStat: function (build: any) {
        const AG = build.SPECIAL.AG.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 3 && AG >= 5) {
          return true
        } else {
          return false
        }
      }
    },
    AdrenalineRush: {
      updateStat: function (build: any) {
        const ST = build.SPECIAL.ST.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 3 && ST >= 5) {
          return true
        } else {
          return false
        }
      }
    },
    QuickRecovery: {
      updateStat: function (build: any) {
        const AG = build.SPECIAL.AG.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 3 && AG >= 6) {
          return true
        } else {
          return false
        }
      }
    },
    WeaponHandling: {
      updateStat: function (build: any) {
        const SG = build.Skills.SmallGuns.value
        const BG = build.Skills.BigGuns.value
        const EW = build.Skills.EnergyWeapons.value
        const TH = build.Skills.Throwing.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 3 && (SG >= 100 || BG >= 100 || EW >= 100 || TH >= 100)) {
          return true
        } else {
          return false
        }
      }
    },
    InYourFace: {
      updateStat: function (build: any) {
        const CC = build.Skills.CloseCombat.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 6 && CC >= 125) {
          return true
        } else {
          return false
        }
      }
    },
    EvenMoreCriticals: {
      updateStat: function (build: any) {
        const SG = build.Skills.SmallGuns.value
        const BG = build.Skills.BigGuns.value
        const EW = build.Skills.EnergyWeapons.value
        const CC = build.Skills.CloseCombat.value
        const TH = build.Skills.Throwing.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 6 && (SG >= 125 || BG >= 125 || EW >= 125 || CC >= 125 || TH >= 125)) {
          return true
        } else {
          return false
        }
      }
    },
    SilentRunning: {
      updateStat: function (build: any) {
        const SN = build.Skills.Sneak.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 6 && SN >= 100) {
          return true
        } else {
          return false
        }
      }
    },
    Toughness: {
      updateStat: function (build: any) {
        const EN = build.SPECIAL.EN.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 6 && EN >= 4) {
          return true
        } else {
          return false
        }
      }
    },
    Sharpshooter: {
      updateStat: function (build: any) {
        const IN = build.SPECIAL.IN.value
        const SG = build.Skills.SmallGuns.value
        const BG = build.Skills.BigGuns.value
        const EW = build.Skills.EnergyWeapons.value
        const CC = build.Skills.CloseCombat.value
        const TH = build.Skills.Throwing.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 9 && IN >= 3 && (SG >= 150 || BG >= 150 || EW >= 150 || CC >= 150 || TH >= 150)) {
          return true
        } else {
          return false
        }
      }
    },
    Pyromaniac: {
      updateStat: function (build: any) {
        const SG = build.Skills.SmallGuns.value
        const BG = build.Skills.BigGuns.value
        const EW = build.Skills.EnergyWeapons.value
        const CC = build.Skills.CloseCombat.value
        const TH = build.Skills.Throwing.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 9 && (SG >= 100 || BG >= 100 || EW >= 100 || CC >= 100 || TH >= 100)) {
          return true
        } else {
          return false
        }
      }
    },
    CloseCombatMaster: {
      updateStat: function (build: any) {
        const CC = build.Skills.CloseCombat.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 9 && CC >= 150) {
          return true
        } else {
          return false
        }
      }
    },
    BonusRangedDamage: {
      updateStat: function (build: any) {
        const SG = build.Skills.SmallGuns.value
        const BG = build.Skills.BigGuns.value
        const EW = build.Skills.EnergyWeapons.value
        const TH = build.Skills.Throwing.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 9 && (SG >= 150 || BG >= 150 || EW >= 150 || TH >= 150)) {
          return true
        } else {
          return false
        }
      }
    },
    EvenTougher: {
      updateStat: function (build: any) {
        const EN = build.SPECIAL.EN.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 9 && EN >= 6) {
          return true
        } else {
          return false
        }
      }
    },
    Stonewall: {
      updateStat: function (build: any) {
        const ST = build.SPECIAL.ST.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 9 && ST >= 6) {
          return true
        } else {
          return false
        }
      }
    },
    Medic: {
      updateStat: function (build: any) {
        const FA = build.Skills.FirstAid.value
        const DC = build.Skills.Doctor.value
        const IN = build.SPECIAL.IN.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 9 && IN >= 3 && FA >= 125 && DC >= 125) {
          return true
        } else {
          return false
        }
      }
    },
    HeaveHo: {
      updateStat: function (build: any) {
        const TH = build.Skills.Throwing.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 9 && TH >= 125) {
          return true
        } else {
          return false
        }
      }
    },
    GainAgility: {
      updateStat: function (build: any) {
        const AG = build.SPECIAL.AG.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 12 && AG <= 9) {
          return true
        } else {
          return false
        }
      }
    },
    GainCharisma: {
      updateStat: function (build: any) {
        const CH = build.SPECIAL.CH.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 12 && CH <= 9) {
          return true
        } else {
          return false
        }
      }
    },
    GainEndurance: {
      updateStat: function (build: any) {
        const EN = build.SPECIAL.EN.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 12 && EN <= 9) {
          return true
        } else {
          return false
        }
      }
    },
    GainIntelligence: {
      updateStat: function (build: any) {
        const IN = build.SPECIAL.IN.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 12 && IN <= 9) {
          return true
        } else {
          return false
        }
      }
    },
    GainLuck: {
      updateStat: function (build: any) {
        const LK = build.SPECIAL.LK.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 12 && LK <= 9) {
          return true
        } else {
          return false
        }
      }
    },
    GainPerception: {
      updateStat: function (build: any) {
        const PE = build.SPECIAL.PE.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 12 && PE <= 9) {
          return true
        } else {
          return false
        }
      }
    },
    GainStrength: {
      updateStat: function (build: any) {
        const ST = build.SPECIAL.ST.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 12 && ST <= 9) {
          return true
        } else {
          return false
        }
      }
    },
    BetterCriticals: {
      updateStat: function (build: any) {
        const SG = build.Skills.SmallGuns.value
        const BG = build.Skills.BigGuns.value
        const EW = build.Skills.EnergyWeapons.value
        const CC = build.Skills.CloseCombat.value
        const TH = build.Skills.Throwing.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 12 && (SG >= 175 || BG >= 175 || EW >= 175 || CC >= 175 || TH >= 175)) {
          return true
        } else {
          return false
        }
      }
    },
    Ghost: {
      updateStat: function (build: any) {
        const SN = build.Skills.Sneak.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 12 && SN >= 150) {
          return true
        } else {
          return false
        }
      }
    },
    Lifegiver1: {
      updateStat: function (build: any) {
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 12) {
          return true
        } else {
          return false
        }
      }
    },
    ActionBoy1: {
      updateStat: function (build: any) {
        const AG = build.SPECIAL.AG.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 12 && AG >= 6) {
          return true
        } else {
          return false
        }
      }
    },
    ActionBoy2: {
      updateStat: function (build: any) {
        const AG = build.SPECIAL.AG.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 15 && AG >= 6) {
          return true
        } else {
          return false
        }
      }
    },
    Lifegiver2: {
      updateStat: function (build: any) {
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 15) {
          return true
        } else {
          return false
        }
      }
    },
    Livewire: {
      updateStat: function (build: any) {
        const AG = build.SPECIAL.AG.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 15 && AG >= 6) {
          return true
        } else {
          return false
        }
      }
    },
    ManОfSteel: {
      updateStat: function (build: any) {
        const EN = build.SPECIAL.EN.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 15 && EN >= 8) {
          return true
        } else {
          return false
        }
      }
    },
    FieldMedic: {
      updateStat: function (build: any) {
        const FA = build.Skills.FirstAid.value
        const DC = build.Skills.Doctor.value
        const medicPerk = build.Perks.Medic.tagged
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 15 && medicPerk && FA >= 175 && DC >= 175) {
          return true
        } else {
          return false
        }
      }
    },
    MoreRangedDamage: {
      updateStat: function (build: any) {
        const SG = build.Skills.SmallGuns.value
        const BG = build.Skills.BigGuns.value
        const EW = build.Skills.EnergyWeapons.value
        const TH = build.Skills.Throwing.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 15 && (SG >= 200 || BG >= 200 || EW >= 200 || TH >= 200)) {
          return true
        } else {
          return false
        }
      }
    },
    SilentDeath: {
      updateStat: function (build: any) {
        const SN = build.Skills.Sneak.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 15 && SN >= 175) {
          return true
        } else {
          return false
        }
      }
    },
    IronLimbs: {
      updateStat: function (build: any) {
        const ST = build.SPECIAL.ST.value
        const EN = build.SPECIAL.EN.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 15 && ST >= 6 && EN >= 6) {
          return true
        } else {
          return false
        }
      }
    },
    Dodger1: {
      updateStat: function (build: any) {
        const AG = build.SPECIAL.AG.value
        const CC = build.Skills.CloseCombat.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 15 && AG >= 8 && CC >= 175) {
          return true
        } else {
          return false
        }
      }
    },
    Dodger2: {
      updateStat: function (build: any) {
        const AG = build.SPECIAL.AG.value
        const CC = build.Skills.CloseCombat.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 18 && AG >= 10 && CC >= 175) {
          return true
        } else {
          return false
        }
      }
    },
    Lifegiver3: {
      updateStat: function (build: any) {
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 18) {
          return true
        } else {
          return false
        }
      }
    },
    BonusRateОfAttack: {
      updateStat: function (build: any) {
        const SG = build.Skills.SmallGuns.value
        const BG = build.Skills.BigGuns.value
        const EW = build.Skills.EnergyWeapons.value
        const CC = build.Skills.CloseCombat.value
        const TH = build.Skills.Throwing.value
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 18 && (SG >= 180 || BG >= 180 || EW >= 180 || CC >= 180 || TH >= 180)) {
          return true
        } else {
          return false
        }
      }
    },
  },
  SupportPerks: {
    Awareness: {
      updateStat: function () {
        return false
      }
    },
    PackRat: {
      updateStat: function (build: any) {
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 6) {
          return true
        } else {
          return false
        }
      }
    },
    StrongBack: {
      updateStat: function (build: any) {
        const EN = build.SPECIAL.EN.value;
        const currentLevel = build.info.currentLevel
        if (currentLevel >= 6 && EN >= 6) {
          return true
        } else {
          return false
        }
      }
    },
    MrFixit: {
      updateStat: function (build: any) {
        const RE = build.Skills.Repair.value;
        if (RE >= 120) {
          return true
        } else {
          return false
        }
      }
    },
    Dismantler: {
      updateStat: function (build: any) {
        const SC = build.Skills.Science.value;
        if (SC >= 120) {
          return true
        } else {
          return false
        }
      }
    },
    DeadManWalking: {
      updateStat: function (build: any) {
        const IN = build.SPECIAL.IN.value;
        const DC = build.Skills.Doctor.value
        if (IN >= 5 && DC >= 50) {
          return true
        } else {
          return false
        }
      }
    },
    SwiftLearner: {
      updateStat: function (build: any) {
        const IN = build.SPECIAL.IN.value;
        const SC = build.Skills.Science.value
        if (IN >= 6 && SC >= 50) {
          return true
        } else {
          return false
        }
      }
    },
    FasterHealing: {
      updateStat: function (build: any) {
        const IN = build.SPECIAL.IN.value;
        const DC = build.Skills.Doctor.value
        if (IN >= 6 && DC >= 75) {
          return true
        } else {
          return false
        }
      }
    },
    RadResistance: {
      updateStat: function (build: any) {
        const IN = build.SPECIAL.IN.value;
        const DC = build.Skills.Doctor.value
        if (IN >= 7 && DC >= 100) {
          return true
        } else {
          return false
        }
      }
    },
    Educated: {
      updateStat: function (build: any) {
        const IN = build.SPECIAL.IN.value;
        const SC = build.Skills.Science.value
        if (IN >= 8 && SC >= 100) {
          return true
        } else {
          return false
        }
      }
    },
    Snakeater: {
      updateStat: function (build: any) {
        const EN = build.SPECIAL.EN.value;
        if (EN >= 6) {
          return true
        } else {
          return false
        }
      }
    },
    GeckoSkinning: {
      updateStat: function (build: any) {
        const OD = build.Skills.Outdoorsman.value;
        if (OD >= 50) {
          return true
        } else {
          return false
        }
      }
    },
    CautiousNature: {
      updateStat: function (build: any) {
        const PE = build.SPECIAL.PE.value;
        const OD = build.Skills.Outdoorsman.value
        if (PE >= 6 && OD >= 100) {
          return true
        } else {
          return false
        }
      }
    },
    Ranger: {
      updateStat: function (build: any) {
        const OD = build.Skills.Outdoorsman.value;
        if (OD >= 100) {
          return true
        } else {
          return false
        }
      }
    },
    Scout: {
      updateStat: function (build: any) {
        const OD = build.Skills.Outdoorsman.value;
        if (OD >= 150) {
          return true
        } else {
          return false
        }
      }
    },
    Explorer: {
      updateStat: function (build: any) {
        const OD = build.Skills.Outdoorsman.value;
        if (OD >= 150) {
          return true
        } else {
          return false
        }
      }
    },
    Pathfinder: {
      updateStat: function (build: any) {
        const OD = build.Skills.Outdoorsman.value;
        if (OD >= 150) {
          return true
        } else {
          return false
        }
      }
    },
    LightStep: {
      updateStat: function (build: any) {
        const TR = build.Skills.Traps.value;
        if (TR >= 50) {
          return true
        } else {
          return false
        }
      }
    },
    DemolitionExpert: {
      updateStat: function (build: any) {
        const TR = build.Skills.Traps.value;
        if (TR >= 125) {
          return true
        } else {
          return false
        }
      }
    },
    Negotiator: {
      updateStat: function (build: any) {
        const BA = build.Skills.Barter.value;
        if (BA >= 125) {
          return true
        } else {
          return false
        }
      }
    },
    SexAppeal: {
      updateStat: function (build: any) {
        const SP = build.Skills.Speech.value;
        if (SP >= 75) {
          return true
        } else {
          return false
        }
      }
    },
    MagneticPersonality: {
      updateStat: function (build: any) {
        const SP = build.Skills.Speech.value;
        if (SP >= 100) {
          return true
        } else {
          return false
        }
      }
    },
    Speaker: {
      updateStat: function (build: any) {
        const SP = build.Skills.Speech.value;
        if (SP >= 125) {
          return true
        } else {
          return false
        }
      }
    },
    StealthGirl: {
      updateStat: function (build: any) {
        const SN = build.Skills.Sneak.value;
        const RE = build.Skills.Repair.value;
        if (SN >= 100 && RE >= 100) {
          return true
        } else {
          return false
        }
      }
    },
    Thief: {
      updateStat: function (build: any) {
        const ST = build.Skills.Steal.value;
        if (ST >= 100) {
          return true
        } else {
          return false
        }
      }
    },
    Harmless: {
      updateStat: function (build: any) {
        const CH = build.SPECIAL.CH.value;
        const ST = build.Skills.Steal.value;
        if (CH >= 6 && ST >= 125) {
          return true
        } else {
          return false
        }
      }
    },
    Pickpocket: {
      updateStat: function (build: any) {
        const ST = build.Skills.Steal.value;
        if (ST >= 125) {
          return true
        } else {
          return false
        }
      }
    },
    MasterThief: {
      updateStat: function (build: any) {
        const ST = build.Skills.Steal.value;
        if (ST >= 125) {
          return true
        } else {
          return false
        }
      }
    },
    TreasureHunter: {
      updateStat: function (build: any) {
        const LP = build.Skills.Lockpick.value;
        if (LP >= 125) {
          return true
        } else {
          return false
        }
      }
    },
    AlcoholRaisedHP1: {
      updateStat: function (build: any) {
        const currentLevel = build.info.currentLevel
        const reqLevel = build.SupportPerks.AlcoholRaisedHP1.level
        const CH = build.SPECIAL.CH.value;
        if (currentLevel >= reqLevel && CH >= 3) {
          return true
        } else {
          return false
        }
      }
    },
    AlcoholRaisedHP2: {
      updateStat: function (build: any) {
        const currentLevel = build.info.currentLevel
        const reqLevel = build.SupportPerks.AlcoholRaisedHP2.level
        const CH = build.SPECIAL.CH.value;
        if (currentLevel >= reqLevel && CH >= 4 && build.SupportPerks.AlcoholRaisedHP1.tagged) {
          return true
        } else {
          return false
        }
      }
    },
  },
  CombatImplants: {
    Dermal1: {
      updateStat: function (build: any) {
        return build.info.currentLevel >= 30 &&
          !build.CombatImplants.Dermal1.tagged
      }
    },
    Phoenix1: {
      updateStat: function (build: any) {
        return build.info.currentLevel >= 30 &&
          !build.CombatImplants.Phoenix1.tagged
      }
    },
    Nemean1: {
      updateStat: function (build: any) {
        return build.info.currentLevel >= 30 &&
          !build.CombatImplants.Nemean1.tagged
      }
    },
    Dermal2: {
      updateStat: function (build: any) {
        return build.info.currentLevel >= 40 &&
          !build.CombatImplants.Dermal2.tagged
      }
    },
    Phoenix2: {
      updateStat: function (build: any) {
        return build.info.currentLevel >= 40 &&
          !build.CombatImplants.Phoenix2.tagged
      }
    },
    Nemean2: {
      updateStat: function (build: any) {
        return build.info.currentLevel >= 40 &&
          !build.CombatImplants.Nemean2.tagged
      }
    },
  },
  Books: {
    SmallGuns: {
      name: 'SmallGuns',
      read: 0,
      gainedSkillPoints: 0,
    },
    FirstAid: {
      name: 'FirstAid',
      read: 0,
      gainedSkillPoints: 0,
    },
    Science: {
      name: 'Science',
      read: 0,
      gainedSkillPoints: 0,
    },
    Repair: {
      name: 'Repair',
      read: 0,
      gainedSkillPoints: 0,
    },
    Barter: {
      name: 'Barter',
      read: 0,
      gainedSkillPoints: 0,
    },
    Outdoorsman: {
      name: 'Outdoorsman',
      read: 0,
      gainedSkillPoints: 0,
    },
  },
  Drugs: {
    Buffout: {
      name: 'Buffout',
      tagged: false,
    },
    NukaCola: {
      name: 'NukaCola',
      tagged: false,
    },
    Psycho: {
      name: 'Psycho',
      tagged: false,
    },
    Cigarettes: {
      name: 'Cigarettes',
      tagged: false,
    },
    Beer: {
      name: 'Beer',
      tagged: false,
    },
    Jet: {
      name: 'Jet',
      tagged: false,
    },
    RadX: {
      name: 'RadX',
      tagged: false,
    },
    Mentats: {
      name: 'Mentats',
      tagged: false,
    },
    CharismaBoost: {
      name: 'CharismaBoost',
      tagged: false,
    },
  },
  Boosts: {
    SmallGuns: {
      updateStat: function (build: any) {
        return build.Skills.SmallGuns.value < 66 && !build.info.marshalBoost
      }
    },
    BigGuns: {
      updateStat: function (build: any) {
        return build.Skills.BigGuns.value < 66 && !build.info.marshalBoost
      }
    },
    EnergyWeapons: {
      updateStat: function (build: any) {
        return build.Skills.EnergyWeapons.value < 66 && !build.info.marshalBoost
      }
    },
    CloseCombat: {
      updateStat: function (build: any) {
        return build.Skills.CloseCombat.value < 91 && !build.info.marshalBoost
      }
    },
    Throwing: {
      updateStat: function (build: any) {
        return build.Skills.Throwing.value < 66 && !build.info.marshalBoost
      }
    },
  },
  Professions: {
    SmallGuns1: {
      updateStat: function (build: any) {
        return build.Skills.Repair.value >= 50 && !build.Professions.SmallGuns1.tagged
      }
    },
    BigGuns1: {
      updateStat: function (build: any) {
        return build.Skills.Repair.value >= 50 && !build.Professions.BigGuns1.tagged
      }
    },
    EnergyExpert1: {
      updateStat: function (build: any) {
        return build.Skills.Science.value >= 50 && !build.Professions.EnergyExpert1.tagged
      }
    },
    DemolitionExpert1: {
      updateStat: function (build: any) {
        return build.Skills.Traps.value >= 50 && !build.Professions.DemolitionExpert1.tagged
      }
    },
    Armorer1: {
      updateStat: function (build: any) {
        return build.Skills.Repair.value >= 50 && !build.Professions.Armorer1.tagged
      }
    },
    Doctor1: {
      updateStat: function (build: any) {
        return build.Skills.Doctor.value >= 50 && !build.Professions.Doctor1.tagged
      }
    },
    SmallGuns2: {
      updateStat: function (build: any) {
        return build.Skills.Repair.value >= 100 && !build.Professions.SmallGuns2.tagged
      }
    },
    BigGuns2: {
      updateStat: function (build: any) {
        return build.Skills.Repair.value >= 100 && !build.Professions.BigGuns2.tagged
      }
    },
    EnergyExpert2: {
      updateStat: function (build: any) {
        return build.Skills.Science.value >= 100 && !build.Professions.EnergyExpert2.tagged
      }
    },
    DemolitionExpert2: {
      updateStat: function (build: any) {
        return build.Skills.Traps.value >= 100 && !build.Professions.DemolitionExpert2.tagged
      }
    },
    Armorer2: {
      updateStat: function (build: any) {
        return build.Skills.Repair.value >= 100 && !build.Professions.Armorer2.tagged
      }
    },
    Doctor2: {
      updateStat: function (build: any) {
        return build.Skills.Doctor.value >= 100 && !build.Professions.Doctor2.tagged
      }
    },
  },
  MysteryBoosts: {
    HubChef: {
      updateStat: function (build: any) {
        const currentLevel = build.info.currentLevel;
        const IN = build.SPECIAL.IN.onDrugs;
        const CH = build.SPECIAL.CH.onDrugs;
        return currentLevel >= 12 && CH >= 4 && IN >= 4;
      }
    },
    FatherTully: {
      updateStat: function (build: any) {
        const currentLevel = build.info.currentLevel;
        const IN = build.SPECIAL.IN.onDrugs;
        const SpeechSkill = build.Skills.Speech.value;
        return currentLevel >= 30 && IN >= 7 && SpeechSkill >= 100;
      }
    },
    Mom: {
      updateStat: function (build: any) {
        const currentLevel = build.info.currentLevel;
        const CH = build.SPECIAL.CH.onDrugs;
        const SpeechSkill = build.Skills.Speech.value;
        return currentLevel >= 24 && CH >= 4 && SpeechSkill >= 100;
      }
    },
    SadFarmer: {
      updateStat: function (build: any) {
        const currentLevel = build.info.currentLevel;
        return currentLevel >= 5;
      }
    },
    LaserBoost: {
      updateStat: function () {
        // ! deprecated
        return false;
      }
    },
    RaidersDie: {
      updateStat: function (build: any) {
        const GamblingSkill = build.Skills.Gambling.value;
        return GamblingSkill < 50;
      }
    },
    Francis: {
      updateStat: function (build: any) {
        const currentLevel = build.info.currentLevel;
        const ST = build.SPECIAL.ST.onDrugs;
        const CloseCombatSkill = build.Skills.CloseCombat.value;
        return currentLevel >= 32 && ST >= 9 && CloseCombatSkill >= 125;
      }
    },
    BecomeAMutant: {
      updateStat: function (build: any) {
        return build.SPECIAL.IN.onDrugs >= 5;
      }
    },
  }
};
