
import { useStore } from "vuex"
import { defineComponent } from "vue"
export default defineComponent({
 name: "CharacterTable",
 components: {},
 setup() {
   const store = useStore()
    const getStatData = (stat: string) => {
      // ! temp fix for non-existing char stats in a saved build
      // TODO remove this fix at some point
      if (!store.state.build.CharStats[stat]) {
        return {
          name: stat,
          value: '--',
        }
      }

      return {
        name: store.state.build.CharStats[stat].name,
        value: store.state.build.CharStats[stat].value,
      };
    };
    return {
      state: store.state,
      getStatData
   }
 },
})
