
import { defineComponent, onMounted } from "vue";
import "./default.css";
import "../../components/desktop/grid-areas.css"
import BuildSpecial from "@/components/desktop/build/BuildSpecial.vue"; // @ is an alias to /src
import BuildCharStats from "@/components/desktop/build/BuildCharStats.vue";
import BuildSkills from "@/components/desktop/build/BuildSkills.vue";
import Traits from "@/components/desktop/build/Traits.vue";
import InfoPanel from "@/components/desktop/build/InfoPanel.vue";
import BuildPool from "@/components/desktop/build/BuildPool.vue";
import BuildFooter from "@/components/desktop/build/BuildFooter.vue";
import BuildHeader from "@/components/desktop/build/BuildHeader.vue";
import ControlPanel from "@/components/desktop/shared/ControlPanel.vue";
import { useStore } from "vuex";
import LevelSpecial from "@/components/desktop/level/LevelSpecial.vue";
import LevelSkills from "@/components/desktop/level/LevelSkills.vue";
import Actions from "@/components/desktop/level/Actions.vue";
import LevelPool from "@/components/desktop/level/LevelPool.vue";
import LevelCharStats from "@/components/desktop/level/LevelCharStats.vue";
// import NavMenuLeft from "@/components/desktop/level/NavMenuLeft.vue";
// import NavMenuRight from "@/components/desktop/level/NavMenuRight.vue";
import LevelHeader from "@/components/desktop/level/LevelHeader.vue";
import Perks from "@/components/desktop/level/Perks.vue";
import SupportPerks from "@/components/desktop/level/SupportPerks.vue";
import BuildNavMenuLeft from "@/components/desktop/build/BuildNavMenuLeft.vue";
import LevelNavMenuRight from "@/components/desktop/level/LevelNavMenuRight.vue";
import LevelNavMenuLeft from "@/components/desktop/level/LevelNavMenuLeft.vue";
import BuildNavMenuRight from "@/components/desktop/build/BuildNavMenuRight.vue";
import EmptyPerks from "@/components/desktop/level/EmptyPerks.vue";
import LevelFooter from "@/components/desktop/level/LevelFooter.vue";
import LoadMenu from "@/components/desktop/shared/LoadMenu.vue";
import SaveMenu from "@/components/desktop/shared/SaveMenu.vue";
import CraftMenu from "@/components/desktop/shared/CraftMenu.vue";
import InventoryMenu from "@/components/desktop/shared/InventoryMenu.vue";

export default defineComponent({
  name: "Home",
  components: {
    BuildSpecial,
    BuildCharStats,
    BuildSkills,
    Traits,
    InfoPanel,
    BuildPool,
    BuildFooter,
    BuildHeader,
    ControlPanel,
    LevelSpecial,
    LevelSkills,
    Actions,
    LevelPool,
    LevelCharStats,
    // NavMenuLeft,
    // NavMenuRight,
    LevelHeader,
    Perks,
    SupportPerks,
    BuildNavMenuLeft,
    LevelNavMenuRight,
    LevelNavMenuLeft,
    BuildNavMenuRight,
    EmptyPerks,
    LevelFooter,
    LoadMenu,
    SaveMenu,
    CraftMenu,
    InventoryMenu
},
  setup() {
      const store = useStore();
      const afterLeave = (e) => {
        const mainClass = e.classList[0]
        const element = document.getElementById('main-container')
        if (mainClass === 'special-container') {
          element.classList.remove('build-state-container')
          element.classList.add('level-state-container')
        } else {
          element.classList.remove('level-state-container')
          element.classList.add('build-state-container')
        }
      }

      onMounted(() => {
        if (store.state.build.info.phase === 'level') {
          const element = document.getElementById('main-container')
          element.classList.remove('build-state-container')
          element.classList.add('level-state-container')
        }
      })
      return {
        state: store.state,
        afterLeave
      };
    },
});
