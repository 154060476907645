export default [
  {
    version: 1.1,
    date: 'Jan 29, 2022',
    items: [
      'App updates are now handled in the Info Panel',
      'Reset Build button is now located in Control Panel',
      'Added Community links in InfoPanel'
    ]
  },
  {
    version: 1.2,
    date: 'Feb 2, 2022',
    items: [
      'ST & EN Implants now add 1HP & 2HP respectively',
      'SPECIAL implants now count for Support Perks requirements',
      'Fixed Dermal & Phoenix implants stats',
      'Power Armor Helmet is added and can be equipped',
      'special thanks to Abyss for the feedback and help :)'
    ]
  },
  {
    version: 1.3,
    date: 'Feb 22, 2022',
    items: [
      'Major layout changes in the Creation page',
      'Major improvements in the Info Panel',
      'Added most of the missing descriptions',
      'Various style improvements',
    ]
  },
  {
    version: 1.4,
    date: 'Jun 18, 2022',
    items: [
      'Added transition animations',
      'Increased font size in CharStats [thx POWERPUFF]',
      'Added Sight Range in CharStats [thx Eybiss]',
      'Enabled Mystery Boosts',
    ]
  },
  {
    version: 1.5,
    date: 'Oct 14, 2022',
    items: [
      'Apple-a-day and Laser boosts are permanently disabled',
      'Laser boost is now merged with Father Tully boost [thx POWERPUFF]',
      'Stats should be updated correctly to reflect the changes',
    ]
  },
  {
    version: 1.6,
    date: 'Jul 18, 2023',
    items: [
      'Critical Chance value now takes into account both Gain Luck perk and gear bonus [thx idkwhattosayk?]',
    ]
  },
  {
    version: 1.7,
    date: 'Sep 8, 2023',
    items: [
      'Fixed a bug with SPECIAL Implants and CharStats [thx POWERPUFF]',
      'Added +3 ST gear bonus on all Power Armors [thx POWERPUFF]',
      'Added info tooltips in Equip Panel [thx POWERPUFF]',
      'Added base Rad Resistance bonus to some armors [thx POWERPUFF]',
      'Changed BH armor skin with CA skin [thx POWERPUFF]',
      'Changed order of DT & DR in Resistances table [thx POWERPUFF]',
    ]
  },
]